<template>
    <div>
        <b-overlay :show="!dataLoaded">
            <b-card>
                <b-button variant="primary" @click="$refs.addLanguageModal.open()">
                    <feather-icon
                        icon="PlusIcon"
                        class="mr-50"
                    />
                    <span class="align-middle">Add</span>
                </b-button>

                <basic-table :columns="columns" :data="languages" v-slot="props">

                    <div v-if="props.column.displayType === 1" class="d-flex justify-content-center" style="min-width: 150px">
                        <img v-if="props.row.icon && props.row.icon.length > 0" :src="'/api/management/v1/image/' + props.row.icon" style="max-width: 100%; max-height: 100px;" class="rounded-lg" alt="Image"/>
                    </div>
                    <span v-else-if="props.column.displayType === 2" class="d-flex justify-content-center">
                        <b-badge v-if="props.formattedRow[props.column.field] === 'Yes'" variant="light-success">
                            Yes
                        </b-badge>
                        <b-badge v-else variant="light-danger">
                            No
                        </b-badge>
                    </span>
                    <div v-else-if="props.column.displayType === 3" class="d-flex justify-content-center">

                        <b-button variant="primary" v-if="!props.row.primary" @click="setLanguageAsPrimary(props.row.id)" class="mr-1">
                            <feather-icon
                                icon="AwardIcon"
                            />
                        </b-button>

                        <b-button variant="warning" @click="$refs.editLanguageModal.open(props.row)" class="mr-1">
                            <feather-icon
                                icon="EditIcon"
                            />
                        </b-button>

                        <b-button variant="danger" @click="removeLanguage(props.row.id)">
                            <feather-icon
                                icon="Trash2Icon"
                            />
                        </b-button>
                    </div>
                    <span v-else>
                        {{ props.formattedRow[props.column.field] }}
                    </span>

                </basic-table>
            </b-card>
        </b-overlay>

        <AddModal ref="addLanguageModal" v-on:itemAdded="loadData"/>

        <EditModal ref="editLanguageModal" v-on:itemModified="loadData"/>

    </div>
</template>
<script>

    import BasicTable from '@/views/components/BasicTable'
    import {BBadge, BButton, BCard, BOverlay} from 'bootstrap-vue'
    import AddModal from '@/views/Language/AddModal'
    import EditModal from '@/views/Language/EditModal'
    export default {
        components: {
            EditModal,
            AddModal,
            BasicTable,
            BOverlay,
            BCard,
            BBadge,
            BButton
        },
        data() {
            return {

                dataLoaded: false,
                languages: [],

                columns: [
                    {
                        label: 'Icon',
                        displayType: 1,
                        field: 'icon',
                        filterOptions: {
                            enabled: false
                        }
                    },
                    {
                        label: 'Name',
                        displayType: 0,
                        field: 'name',
                        filterOptions: {
                            enabled: true,
                            placeholder: 'Search Name'
                        }
                    },
                    {
                        label: 'Enabled',
                        displayType: 2,
                        field(rowObj) {
                            return (rowObj.enabled) ? 'Yes' : 'No'
                        },
                        filterOptions: {
                            enabled: true,
                            placeholder: 'Yes / No'
                        }
                    },
                    {
                        label: 'Primary',
                        displayType: 2,
                        field(rowObj) {
                            return (rowObj.primary) ? 'Yes' : 'No'
                        },
                        filterOptions: {
                            enabled: true,
                            placeholder: 'Yes / No'
                        }
                    },
                    {
                        label: 'Local Name',
                        displayType: 0,
                        field: 'local_name',
                        filterOptions: {
                            enabled: true,
                            placeholder: 'Search Local Name'
                        }
                    },
                    {
                        label: 'Actions',
                        displayType: 3,
                        field: 'actions',
                        filterOptions: {
                            enabled: false
                        }
                    }
                ]
            }
        },
        methods: {
            loadData() {

                this.dataLoaded = false

                const thisIns = this
                const loadPromise = this.$http.get('/api/management/v1/language')
                loadPromise.then(function(response) {
                    thisIns.languages = response.data
                }).catch(function(error) {
                    thisIns.$printError(error.response.data)
                }).finally(function() {
                    thisIns.dataLoaded = true
                })
            },
            setLanguageAsPrimary(id) {
                const thisIns = this
                const loadPromise = this.$http.put(`/api/management/v1/language/${  id}/set_as_primary`)
                loadPromise.then(function() {
                    thisIns.$printSuccess('Language set as primary')
                }).catch(function(error) {
                    thisIns.$printError(error.response.data)
                }).finally(function() {
                    thisIns.loadData()
                })
            },
            removeLanguage(id) {

                const thisIns = this
                const loadPromise = this.$http.delete(`/api/management/v1/language/${  id}`)
                loadPromise.then(function() {
                    thisIns.$printSuccess('Language removed')
                }).catch(function(error) {
                    thisIns.$printError(error.response.data)
                }).finally(function() {
                    thisIns.loadData()
                })
            }
        },
        mounted() {
            this.loadData()
        }
    }
</script>