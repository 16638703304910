<template>
    <b-modal title="Add new" v-model="addModalActive" no-close-on-backdrop>
        <template #default>
            <b-form-group>
                <b-form-checkbox v-model="addObject.enabled" class="mt-2">Enabled</b-form-checkbox>
            </b-form-group>
            <b-form-group>
                <label>Name:</label>
                <b-form-input
                    type="text"
                    placeholder="Name"
                    v-model="addObject.name"
                />
            </b-form-group>
            <b-form-group>
                <label>Local Name:</label>
                <b-form-input
                    type="text"
                    placeholder="Name"
                    v-model="addObject.local_name"
                />
            </b-form-group>
            <b-form-group>
                <label>Image:</label>
                <ImageSelector v-model="addObject.icon"/>
            </b-form-group>
        </template>
        <template #modal-footer>

            <b-button variant="danger" @click="addModalActive = false">
                <feather-icon
                    icon="XIcon"
                    class="mr-50"
                />
                <span class="align-middle">Close</span>
            </b-button>

            <b-button variant="primary" @click="addLanguage">
                <feather-icon
                    icon="PlusIcon"
                    class="mr-50"
                />
                <span class="align-middle">Add</span>
            </b-button>
        </template>
    </b-modal>
</template>
<script>

    import {BButton, BFormCheckbox, BFormGroup, BFormInput, BModal} from 'bootstrap-vue'
    import ImageSelector from '@/views/components/ImageSelector'

    export default {
        components: {
            ImageSelector,
            BButton,
            BModal,
            BFormInput,
            BFormGroup,
            BFormCheckbox
        },
        data() {
            return {
                addObject: {
                    enabled: true
                },
                addModalActive: false
            }
        },
        methods: {
            open() {
                this.addModalActive = true
            },
            addLanguage() {
                const thisIns = this
                const loadPromise = this.$http.post('/api/management/v1/language', this.addObject)
                loadPromise.then(function(response) {
                    thisIns.$printSuccess('Language added')
                    thisIns.addModalActive = false
                    thisIns.addObject = {enabled:true}
                    thisIns.$emit('itemAdded', response.data.id)
                }).catch(function(error) {
                    thisIns.$printError(error.response.data)
                })
            }
        }
    }

</script>