<template>
    <b-modal title="Add new" v-model="editModalActive" no-close-on-backdrop>
        <template #default>
            <b-form-group>
                <b-form-checkbox v-model="editObject.enabled" class="mt-2">Enabled</b-form-checkbox>
            </b-form-group>
            <b-form-group>
                <label>Name:</label>
                <b-form-input
                    type="text"
                    placeholder="Name"
                    v-model="editObject.name"
                />
            </b-form-group>
            <b-form-group>
                <label>Local Name:</label>
                <b-form-input
                    type="text"
                    placeholder="Name"
                    v-model="editObject.local_name"
                />
            </b-form-group>
            <b-form-group>
                <label>Image:</label>
                <ImageSelector v-model="editObject.icon"/>
            </b-form-group>
        </template>
        <template #modal-footer>

            <b-button variant="danger" @click="addModalActive = false">
                <feather-icon
                    icon="XIcon"
                    class="mr-50"
                />
                <span class="align-middle">Close</span>
            </b-button>

            <b-button variant="warning" @click="editLanguage">
                <feather-icon
                    icon="SaveIcon"
                    class="mr-50"
                />
                <span class="align-middle">Save</span>
            </b-button>
        </template>
    </b-modal>
</template>
<script>

    import {BButton, BFormCheckbox, BFormGroup, BFormInput, BModal} from 'bootstrap-vue'
    import ImageSelector from '@/views/components/ImageSelector'

    export default {
        components: {
            ImageSelector,
            BButton,
            BModal,
            BFormInput,
            BFormGroup,
            BFormCheckbox
        },
        data() {
            return {
                editObject: {},
                editModalActive: false
            }
        },
        methods: {
            open(item) {
                this.editObject = JSON.parse(JSON.stringify(item))
                this.editModalActive = true
            },
            editLanguage() {
                const thisIns = this
                const loadPromise = this.$http.put(`/api/management/v1/language/${  this.editObject.id}`, this.editObject)
                loadPromise.then(function() {
                    thisIns.$printSuccess('Language saved')
                    thisIns.editModalActive = false
                    thisIns.editObject = {}
                    thisIns.$emit('itemModified')
                }).catch(function(error) {
                    thisIns.$printError(error.response.data)
                })
            }
        }
    }

</script>